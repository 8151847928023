import React, { useState, createContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
   const [isLoggedIn, setIsLoggedIn] = useState(false);

   useEffect(() => {
      let token = sessionStorage?.getItem("jwtToken")
      if (token && token?.includes("Bearer")) {
         login()
      }
   }, [])


   const login = () => {
      setIsLoggedIn(true);
   };

   const logout = () => {
      setIsLoggedIn(false);
   };

   return (
      <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
         {children}
      </AuthContext.Provider>
   );
}


ReactDOM.render(

   <React.Fragment>
      <HelmetProvider>
         <Helmet>
            <meta name="robots" content="noindex, nofollow" />
            <title>SV4 DEV</title>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
         </Helmet>
         <AuthProvider>
            <Router>
               <Routes>
                  <Route path="/" element={<App />} />
               </Routes>
            </Router>
         </AuthProvider>
      </HelmetProvider>
   </React.Fragment>,
   document.getElementById("root")
);

reportWebVitals();
