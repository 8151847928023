import "./scss/main.scss";
import { useEffect, useState, useRef, useContext } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import { AuthContext } from "./index";
import { useNavigate } from 'react-router-dom';

const BASE_URL = "https://cms.snadnavolba.cz/api/";

function App() {
   const navigate = useNavigate();
   const nameField = useRef(null)
   const pwdField = useRef(null)

   const { isLoggedIn, login } = useContext(AuthContext);


   async function cmsLogin() {
      const loginData = {
         identifier: nameField.current.value,
         password: pwdField.current.value,
      };

      if (!loginData.password || !loginData.identifier) {
         alert("Vyplň údaje!")
         return
      }

      const req = await fetch(`${BASE_URL}auth/local`, {
         method: "POST",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
         },
         body: JSON.stringify(loginData),
      });

      const loginResponseData = await req.json();
      //console.log(loginResponseData);

      if (req.status === 400) {
         alert("Chyba přihlášení - špatné údaje");
         return;
      }
      if (loginResponseData.jwt) {
         login();
      }
      sessionStorage.setItem("jwtToken", `Bearer ${loginResponseData.jwt}`)
      //return `Bearer ${loginResponseData.jwt}`;

   }

   const handleKeyDown = (event) => {

      console.log("ke")
      if (event.key === 'Enter') {
         cmsLogin()
      }
   }

   useEffect(() => {
      if (isLoggedIn) {
         navigate("/app");
      }
   }, [isLoggedIn]);

   return (
      <div className="App">
         <Container maxWidth="xl">
            <div className="flex justify-center w-full">
               <div className="flex flex-col items-center justify-center mb-20" style={{ width: "300px", gap: "20px", marginTop: "150px" }}>
                  <h1>Přihlášení</h1>
                  <TextField name="username" required label="Jméno" inputRef={nameField} onKeyDown={handleKeyDown} />
                  <TextField name="password" type="password" required label="Heslo" inputRef={pwdField} onKeyDown={handleKeyDown} />

                  <Button
                     variant="contained"
                     className="input"
                     onClick={() => cmsLogin()}

                  >
                     Přihlásit
                  </Button>
               </div>
            </div>
         </Container>
      </div>
   );
}

export default App;
