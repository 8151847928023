import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List'
import Input from '@mui/material/Input'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'


const ProsCons = ({ buttonTitle = "Pros/Cons", setter, items = [1, 2, 3] }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: 800,
    background: 'white',
    border: 'none',
    borderRadius: "10px",
    boxShadow: 24,
    padding: "30px",
  };


  return (
    <div>
      <Button onClick={handleOpen}>{buttonTitle}</Button>
      <Modal open={open} onClose={handleClose} >
        <Box sx={styles}>
          <h2 style={{ marginTop: 0 }}>Vyber klady</h2>
          <TransferList items={items} setter={setter} close={handleClose} />
        </Box>
      </Modal>
    </div>
  );
};

export default ProsCons;

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}


function TransferList({ items, setter, close }) {
  
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(items);
  const [right, setRight] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    if (setter) setter(right);
  }, [right])


  const handleChange = (e, index) => {
    const newInputValues = [...left];
    newInputValues[index] = e.target.value;
    setLeft(newInputValues);
  }

  const customList = (items, readOnly) => (
    <Paper sx={{ width: 280, height: 250, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value, index) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              {readOnly ? <ListItemText id={labelId} primary={`${value}`} /> :
                <Input id={labelId} defaultValue={value} onBlur={(e) => handleChange(e, index)} />
              }
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">

          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>

        </Grid>
      </Grid>
      <Grid item>{customList(right, true)}</Grid>
      <Grid item>
        <Button variant="contained" onClick={close}>Hotovo</Button>
      </Grid>
    </Grid>

  );
};
