import dictionary from "./translation/en-cs.json";

async function gsmarena(url) {
   if (!url.includes("https://www.gsmarena.com/")) {
      alert("Špatná url!");
      return;
   }
   try {
      const req = await fetch(url);
      console.log(req);
      const html = await req.text();
      const phone = await grabber(html, url);
      return phone;
   } catch (err) {
      console.log(err);
      return false;
   }
}

function grabber(html, gsmLink) {
   //console.log(html);
   const parser = new DOMParser();
   const doc = parser.parseFromString(html, "text/html");

   if (!doc.querySelector("[data-spec=modelname]")) {
      alert("Nenalezena relevantní data");
      return;
   }

   function slugify(str) {
      str = str.replace(/^\s+|\s+$/g, "");
      // Make the string lowercase
      str = str.toLowerCase();
      // Remove accents, swap ñ for n, etc
      var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
      var to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
      for (var i = 0, l = from.length; i < l; i++) {
         str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }
      // Remove invalid chars
      str = str
         .replace(/[^a-z0-9 -]/g, "")
         // Collapse whitespace and replace by -
         .replace(/\s+/g, "-")
         // Collapse dashes
         .replace(/-+/g, "-");
      return str;
   }

   const extractNumber = (string) => {
      if (string === undefined) return;
      return string.replace(",", ".").replace(/[^0-9&.]/g, "");
   };

   const dimensions = (index) => {
      let str = doc.querySelector("[data-spec=dimensions]").innerText.split(" mm");
      let arr = str[0].split(" x ");
      return extractNumber(arr[index]);
   };

   const multiLineToArray = (string) => {
      if (string === undefined) return;
      let str = string.replace(/\r?\n|\r/g, " ").split("<br>");
      let trimmedArr = [];
      str.forEach((n) => trimmedArr.push(n.trim()));
      return trimmedArr;
   };

   const charging = (string) => {
      if (string === undefined) return;
      let standard = [],
         wireless = [];
      let arr = string.split("<br>");
      arr.forEach((item) => {
         item.includes("bezdrátové") ? wireless.push(item.replace("\n", "")) : standard.push(item.replace("\n", ""));
      });

      return [standard, wireless];
   };

   const extractDisplayProperties = () => {
      let displayType = getStr("displaytype").split(", ");
      let displaySize = getStr("displaysize").split(", ");
      let displayResolution = getStr("displayresolution")?.split(", ");
      let displayRatio = displayResolution[1]?.split("(~");
      if (displayResolution.length > 1) {
         displayResolution[1].split("(~");
      }
      let bodyDisplayRatio = displaySize[1].split("(~")[1].split("%")[0];

      const obj = {
         type: displayType[0],
         size: displaySize[0]?.replace(" palců", ""),
         bodyDisplayRatio: bodyDisplayRatio,
         hz: "",
         nits: "",
         hdr: "",
         dolby: "",
         ratio: displayRatio?.[0]?.replace(" poměr ", ""),
         resolution: displayResolution?.[0].replace(" pixelů", ""),
         ppi: displayRatio?.[1] && extractNumber(displayRatio[1]),
      };
      displayType.forEach((item) => {
         let x = item.toLowerCase();
         switch (true) {
            case x.includes("hz"):
               obj.hz = extractNumber(item);
               break;
            case x.includes("nitů"):
               obj.nits = extractNumber(item);
               break;
            case x.includes("hdr"):
               obj.hdr = item;
               break;
            case x.includes("dolby vision"):
               obj.dolby = item;
               break;
            default:
               break;
         }
      });

      return obj;
   };

   const translate = (translatedText) => {
      let string = translatedText;
      for (const key in dictionary) {
         if (Object.hasOwnProperty.call(dictionary, key)) {
            const translation = dictionary[key];
            const original = [key];
            let regex = new RegExp(`(?<=[\\s,.:;(<>/"']|^)${original}(?=[\\s,.:;)<>/"']|$)`);
            if (string.match(regex)) {
               while (string.match(regex)) {
                  const next = string.match(regex);
                  //console.log(next);
                  let arr = string.split("");
                  arr.splice(next.index, next[0].length, translation);
                  string = arr.join("");
               }
            }
         }
      }
      return string;
   };

   const createDate = (string) => {
      //Available. Released 2021, January 29 > 2022-03-22
      let numbers;
      numbers = extractNumber(string).split(".");
      numbers = numbers.filter(Boolean);
      const months = ["leden", "únor", "březen", "duben", "květen", "červen", "červenec", "srpen", "září", "říjen", "listopad", "prosinec"];
      months.forEach((month, index) => {
         if (string.includes(month)) {
            if (index + 1 < 10) {
               numbers.push(`0${index + 1}`);
            } else {
               numbers.push(index + 1);
            }
         }
      });
      if (Array.isArray(numbers) && numbers.length === 3) {
         return `${numbers[0]}-${numbers[2]}-${numbers[1]}`;
      } else {
         return null;
      }
   };

   const changeDateOrder = (string) => {
      let count = 0;
      let array = string.split("");

      array.forEach((item) => {
         if (typeof Number(item) === "number" && !isNaN(item) && item !== " ") {
            count = count + 1;
         }
      });

      if (count >= 6) {
         let arr = string.split(" ");
         let date = arr.splice(arr.length - 1, 1);
         arr.splice(arr.length - 1, 0, date + ".");
         return arr.join(" ");
      } else {
         return string;
      }
   };

   const getStr = (query, disableTranslation = false) => {
      let target = doc.querySelector("[data-spec=" + query + "]");
      if (target && !disableTranslation) {
         return translate(target.innerHTML);
      } else if (target && disableTranslation) {
         return target.innerHTML;
      } else {
         console.log("Nenalezen element: ", query);
         return undefined;
      }
   };

   const extractWR = (target) => {
      if (target === undefined) return { ip: "", bodyOther: "", stylus: "" };
      let ip,
         bodyOther = [],
         stylus;

      console.log(target);
      let data = target?.split("<br>");
      if (data.length > 0) {
         data.forEach((item, index) => {
            console.log("item", item);
            if (item.includes("stylusu")) {
               stylus = item;
            } else if (item.includes("vodě")) {
               ip = item;
            } else {
               bodyOther.push(item);
            }
         });
         bodyOther = bodyOther.join(",");
      }

      //console.log(ip, bodyOther, stylus);
      return { ip, bodyOther, stylus };
   };

   const findUnnamed = (queryString) => {
      const searchArea = doc.querySelectorAll("#specs-list tr td");
      for (const a of searchArea) {
         if (a.textContent.includes(queryString)) {
            return translate(a.nextElementSibling.innerHTML);
         }
      }
   };

   const cleanup = (string) => {
      if(!string) return
      let arr = string.split(" ");
      arr.forEach((item, index) => {
         if (item === "&amp;") arr[index] = "&";
      });
      return arr.join(" ");
   };

   let displayProps = extractDisplayProperties();

   const batteryRegex = /\b(\d+)\s*mAh\b/;

   let json = {
      title: getStr("modelname"),
      width: dimensions(0),
      height: dimensions(1),
      depth: dimensions(2),
      weight: getStr("weight")?.split(" g", 1).toString(),
      slug: slugify(getStr("modelname")),
      network: getStr("nettech"),
      speed: getStr("speed"),
      announced: createDate(getStr("year")),
      status: changeDateOrder(getStr("status")),
      released: createDate(getStr("status")),
      body: getStr("build"),
      bodyOther: extractWR(getStr("bodyother")).bodyOther,
      stylus: extractWR(getStr("bodyother")).stylus,
      waterResistant: extractWR(getStr("bodyother")).ip,
      sim: getStr("sim"),
      displayType: displayProps.type,
      displaySize: displayProps.size,
      displayResolution: displayProps.resolution,
      displayFrequency: displayProps.hz,
      displayNits: displayProps.nits,
      displayPpi: displayProps.ppi,
      displayDolbyVision: displayProps.dolby,
      displayHdr: displayProps.hdr,
      displayRatio: displayProps.ratio,
      displayToBodyRatio: displayProps.bodyDisplayRatio,
      displayProtection: getStr("displayprotection"),
      displayOther: getStr("displayother"),
      displayBezel: "",
      os: getStr("os"),
      chipset: getStr("chipset")?.split(" - Mezinárodní")[0],
      cpu: cleanup(getStr("cpu")?.split(" - Mezinárodní")[0]),
      gpu: getStr("gpu")?.split(" - Mezinárodní")[0],
      ram: getStr("internalmemory"),
      memory: getStr("internalmemory"),
      memoryType: getStr("memoryother")?.replace("<br>", ""),
      memorySlot: getStr("memoryslot"),
      mainCameras: multiLineToArray(getStr("cam1modules")),
      mainCamerasFeatures: getStr("cam1features"),
      mainCamerasVideo: getStr("cam1video"),
      frontCameras: multiLineToArray(getStr("cam2modules")),
      frontCamerasFeatures: getStr("cam2features"),
      frontCamerasVideo: getStr("cam2video"),
      speakers: findUnnamed("Loudspeaker"),
      jack: findUnnamed("3.5mm jack"),
      wlan: getStr("wlan"),
      bluetooth: getStr("bluetooth"),
      gps: getStr("gps"),
      nfc: getStr("nfc"),
      radio: getStr("radio"),
      usb: getStr("usb"),
      sensors: getStr("sensors"),
      features: multiLineToArray(getStr("featuresother")),
      battery: getStr("batdescription1").match(batteryRegex)[1],
      charging: charging(findUnnamed("Charging"))?.[0],
      wirelessCharging: charging(findUnnamed("Charging"))?.[1],
      colors: getStr("colors"),
      sarEu: getStr("sar-eu")?.trim(),
      benchmarks: multiLineToArray(getStr("tbench")),
      gsmLink: gsmLink,
      class: "flagship",
      codeName: ""
   };

   console.log(json);

   return json;
}

export default gsmarena;
