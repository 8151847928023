import "./scss/main.scss";
import { useEffect, useState, useRef, useMemo, useCallback, useContext } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import gsmarena from "./gsmarena";
import smarty from "./smarty";
import alzaNotebooky from "./alzaNotebooky";
import alzaTelevize from "./alzaTelevize";
import heureka from "./heureka";
import RUG from "react-upload-gallery";
import "react-upload-gallery/dist/style.css"; // or scss
//import ButtonGroup from "@mui/material/ButtonGroup";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import { phone } from "./params/phone";
import { PhoneFields } from "./fieldset/phoneFields";
import { strapiComponent } from "./params/strapiComponent";
import { NotebookFields } from "./fieldset/notebookFields";
import _ from 'lodash';
import { AuthContext, AuthProvider } from "./index";
import { InputAdornment, IconButton } from '@mui/material';
import Login from "./Login"
import { Clear } from '@mui/icons-material';
import ProsCons from "./components/ModalConsPros";
import { prop } from "cheerio/lib/api/attributes";

const modalStyle = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 400,
   bgcolor: 'background.paper',
   border: 'none',
   borderRadius: "10px",
   boxShadow: 24,
   p: 4,
};


const BASE_URL = "https://cms.snadnavolba.cz/api/";

const DEFAULT = {
   alzaLink: "",
   mallLink: "",
   okayLink: "",
   expertLink: "",
   smartyLink: "",
   comforLink: "",
   class: "unset",
   component: {},
};

function App() {
   const { isLoggedIn, logout } = useContext(AuthContext);
   const [modalOpen, setModalOpen] = useState(false);
   const handleClose = () => setModalOpen(false);
   const [fetchedData, setFetchedData] = useState(DEFAULT);
   const urlField = useRef();
   const urlFieldHeureka = useRef();
   const [category, setCategory] = useState(1);
   const [imageSource, setImageSource] = useState("heureka");
   const [memoryVariant, setMemoryVariant] = useState("");
   const [galleryRefs, setGalleryRefs] = useState([]);
   const [sourceUrl, setSourceUrl] = useState("https://www.gsmarena.com/samsung_galaxy_s23_ultra-12024.php");
   const [heurekaUrl, setHeurekaUrl] = useState("https://mobilni-telefony.heureka.cz/samsung-galaxy-s23-ultra-s918b-8gb-256gb");

   const [initReviews, setInitReviews] = useState()
   const [pros, setPros] = useState([])
   const [cons, setCons] = useState([])


   const getJwt = async () => {
      let token = sessionStorage?.getItem("jwtToken")
      if (!token) //sessionStorage.setItem("jwtToken", await login())
         console.log("TOKEN RETRIEVED", token)
   }

   const handleMerge = (obj1, obj2) => {
      return _.mergeWith(obj1, obj2, (val1, val2) => {
         if (val1) return val1
         if (!val2) return val1
         return val2
         //return val2 === '' ? val1 : val2;
      });
      //setMerged(result);
   };

   const combineParams = async () => {
      // Kategorie notebooky - donačítání parametrů z druhého e-shopu
      if (category === 3 && (fetchedData.alzaLink || fetchedData.smartyLink)) {
         let DATA
         if (urlField.current.value?.includes("smarty")) {
            DATA = await alzaNotebooky(fetchedData.alzaLink + "#parametry");
         }
         if (urlField.current.value?.includes("alza")) {
            DATA = await smarty(fetchedData.smartyLink);
         }
         if (DATA) {
            setFetchedData((prev) => ({
               ...prev,
               component: { ...handleMerge(prev.component, DATA) }
            }));
         }
      } else {
         alert("Nemáš sekundární link, ze kterého by se cucaly parametry!")
      }
   }
   // Load settings z local storage
   useEffect(() => {
      getJwt()
      const cat = localStorage.getItem("category");
      const imageSource = localStorage.getItem("imageSource");
      if (cat) setCategory(+cat);
      if (imageSource) setImageSource(imageSource);
   }, []);


   const setCategoryHandler = (cat) => {
      localStorage.setItem("category", cat);
      setCategory(cat);
   };

   const setImageSourceHandler = (imageSource) => {
      localStorage.setItem("imageSource", imageSource);
      setImageSource(imageSource);
   };

   async function loadData(url) {
      let DATA;
      let x = url;
      switch (true) {
         case x.includes("gsmarena"):
            DATA = await gsmarena(url);
            break;
         case x.includes("smarty"):
            DATA = await smarty(url);
            break;
         case x.includes("alza") && category === 3:
            DATA = await alzaNotebooky(url);
            break;
         case x.includes("alza") && category === 4:
            DATA = await alzaTelevize(url);
            break;
         default:
            break;
      }
      if (!DATA) {
         alert("ŽÁDNÉ DATA");
         return;
      }
      if (DATA) {
         console.log("DDDDD", DATA)
         if (fetchedData.component.images) delete DATA.images;
         /*          setFetchedData((prev) => ({
                     ...prev,
                     component: { ...handleMerge(prev.component, DATA) }
                  })); */
         setFetchedData((prev) => ({
            ...prev,
            component: { ...prev.component, ...DATA },
         }));
      }
   }

   var RAW = {
      data: {
         title: fetchedData?.component?.title,
         slug: fetchedData.component?.slug,
         ratingHeureka: fetchedData.rating,
         priceHeureka: fetchedData.price,
         class: fetchedData.class,
         codeName: fetchedData?.component?.codeName,
         category: category,
         released: fetchedData?.component?.released,
         params: [
            {
               __component: "specs.dimensions",
               width: fetchedData.component?.width,
               height: fetchedData.component?.height,
               depth: fetchedData.component?.depth,
               weight: fetchedData.component?.weight,
            },
         ],
         affiliate: [
            {
               __component: "affiliate.links",
               gsm: fetchedData.gsmLink,
               heureka: heurekaUrl,
               alza: fetchedData.alzaLink,
               mall: fetchedData.mallLink,
               okay: fetchedData.okayLink,
               expert: fetchedData.expertLink,
               comfor: fetchedData.comforLink,
               exasoft: fetchedData.exasoftLink,
               mobilplus: fetchedData.mobilplusLink,
               smarty: fetchedData.component?.smartyLink || fetchedData.smartyLink || "",
            },
            {
               __component: "affiliate.heureka",
               link: heurekaUrl,
               productId: fetchedData.productId,
               reviewsCount: fetchedData.reviewsCount,
               rating: fetchedData.rating,
               price: fetchedData.price,
            },
         ],
      },
   };

   //console.log(RAW);


   const handleSourceSelect = (link) => {
      setSourceUrl(link)
      setModalOpen(false)
      loadData(link)
   }

   async function loadDataHeureka(url) {
      let heurekaData = await heureka(url);
      if (!heurekaData) {
         alert("Data z Heureky se nepodařilo načíst. Možná je špatná url nebo je zapnutý adblock / CORS problém");
         return;
      }
      if (fetchedData?.component.images?.length > 0) delete heurekaData.images;

      if (imageSource === "heureka") {
         setFetchedData((prev) => ({
            ...prev,
            component: {
               ...prev.component,
               images: heurekaData.images,
            },
            ...heurekaData,
         }));
      } else {
         delete heurekaData.images;
         setFetchedData((prev) => ({
            ...prev,
            ...heurekaData,
         }));
      }
      if ((heurekaData.alzaLink || heurekaData.smartyLink) && category !== 1 && category !== 2) setModalOpen(true)
      //console.log(heurekaData.reviews, "tadyyyyyyy")
      formattedReviewData(heurekaData.reviews)
   }

   useEffect(() => {
      console.log("DATA", fetchedData);
   }, [fetchedData]);


   const formattedReviewData = (data) => {
      const prosArr = []
      const consArr = []

      data.forEach((item) => {
         const { pros, cons } = item;
         const formattedPros = pros.filter(Boolean);
         const formattedCons = cons.filter(Boolean);

         if (formattedPros.length > 0) prosArr.push(...formattedPros)
         if (formattedCons.length > 0) consArr.push(...formattedCons)
      });

      setInitReviews({pros: prosArr, cons: consArr});
   };

   useEffect(() => {
      console.log("PROS CONS", pros, cons);
   }, [pros, cons]);

   useEffect(() => {
      if (fetchedData.component?.title) {
         setFetchedData((prev) => ({
            ...prev,
            component: {
               ...prev.component,
               slug: slugify(fetchedData.component?.title),
            },
         }));
      }
   }, [fetchedData.component?.title]);

   const onChangeHandler = (e, nesting) => {
      const { name, value } = e.target;
      let data = value;
      if (name.includes("Link")) {
         var link = value?.split("utm_")[0];
         if (value.includes("?src=sug")) link = value?.split("?")[0]
         if (link.slice(-1) === "?" || link.slice(-1) === "&") {
            data = link.slice(0, -1)
         }
         else {
            data = link
         }
      }
      console.log(data, name);
      if (nesting) {
         setFetchedData((prev) => ({
            ...prev,
            component: {
               ...prev.component,
               [name]: data,
            },
         }));
      } else {
         setFetchedData((prev) => ({
            ...prev,
            [name]: data,
         }));
      }
   };

   const memoryVariantChangeHandler = (event) => {
      if (!fetchedData.component.title) {
         alert("Načti nejdřív specs produktu")
      }
      capacityChange(event.target.value);
      setMemoryVariant(event.target.value);
   };

   const handleEmptyHeureka = () => {
      console.log("handle empty");
      setHeurekaUrl("");
      setFetchedData((prev) => ({
         ...prev,
         link: "",
         productId: "",
         reviewsCount: "",
         rating: 0,
         price: 0,
         ratingHeureka: 0,
         priceHeureka: 0,
      }));
   };

   const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

   async function onSubmit(data) {
      console.log("submiting form");

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", sessionStorage.getItem("jwtToken"))

      var arrCopy = { ...RAW };
      if (arrCopy.data.params.length !== 2) {

         if (category === 1 || category === 2) arrCopy.data.params.splice(0, 0, phone(fetchedData.component));
         if (category === 3) arrCopy.data.params.splice(0, 0, strapiComponent(fetchedData.component, "specs.notebook"));
         if (category === 4) arrCopy.data.params.splice(0, 0, strapiComponent(fetchedData.component, "specs.television"));
      }

      if (arrCopy.data.params.length === 1) {
         alert("Chybí PARAMS!!!")
         return
      }

      if (!heurekaUrl) {
         console.log("mazu affiliate.heureka");
         arrCopy.data.affiliate.splice(1, 1);
      }

      var reqOpt = {
         method: "POST",
         headers: myHeaders,
         body: JSON.stringify(arrCopy),
         redirect: "follow",
      };

      const submitAll = async () => {
         let imagesIDs = [];
         try {
            const req = await fetch(BASE_URL + "products", reqOpt);
            const res = await req.json();
            console.log("Odpověď CMS serveru", res)
            if (res?.error?.message) {
               const errorMessages = res?.error.details.errors.map(error => `${error.path.join(".")} - ${error.message}`).join("\n");
               alert(
                  res?.error?.details?.errors?.[0].path[0] === "slug" ? "PRODUKT UŽ EXISTUJE!" :
                     `Status: ${res?.error.status}\nName: ${res?.error.name}\nMessage: ${res?.error.message}\n\nErrors:\n${errorMessages}`
               )
               return
            }

            if (fetchedData.component.images && galleryRefs.length === 0) {
               for (const url of fetchedData.component.images) {
                  try {
                     const response = await fetch(url.source);
                     const blob = await response.blob();
                     const file = new File([blob], `${fetchedData.component?.title}`, { type: url?.file?.type || 'image/jpeg' });
                     var imageHeaders = new Headers();
                     imageHeaders.append("Authorization", sessionStorage.getItem("jwtToken"))
                     var formdata = new FormData();
                     formdata.append("ref", "api::product.product");
                     formdata.append("refId", res.data.id);
                     formdata.append("field", "photos");
                     formdata.append("files", file, `${fetchedData.component?.title}`);
                     var imageRequestOptions = {
                        method: "POST",
                        headers: imageHeaders,
                        body: formdata,
                        redirect: "follow",
                     };

                     console.log("sending", file);
                     fetch(BASE_URL + "upload", imageRequestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                           console.log("RESULT", result)
                           imagesIDs.push({ id: result[0].id });
                           console.log(result);
                        })
                        //.then((result) => setFetchedData({}))
                        .catch((error) => console.log("error", error.message));
                     console.log("čekám 200ms");
                     await delay(200);
                  } catch (err) {
                     alert(err.message);
                  }
               }
               setGalleryRefs(imagesIDs);
            } else {
               // Když obrázky jsou uploadovány, přidat ID obrázků k produktu
               try {
                  console.log(galleryRefs);
                  if (!res.data.id) alert("nemám REF-ID");
                  var myHeaders = new Headers();
                  myHeaders.append("Content-Type", "application/json");
                  myHeaders.append("Authorization", sessionStorage.getItem("jwtToken"))

                  const body = JSON.stringify({
                     data: {
                        photos: [...galleryRefs],
                     },
                  });

                  var reqOpt2 = {
                     method: "PUT",
                     headers: myHeaders,
                     body: body,
                     redirect: "follow",
                  };

                  console.log(JSON.stringify(body));
                  const url = `https://cms.snadnavolba.cz/api/products/${res.data.id}`;
                  await fetch(url, reqOpt2);
               } catch (err) {
                  console.log(err.message);
               }
            }
            alert("Odesláno!")
         } catch (err) {
            alert(`Chyba odesílání: ${err}`);
         }
      };

      await submitAll();
      console.log("finished");

      //await rebuild(fetchedData.component.slug); není třeba, serverSide

   }

   const capacityChange = (value) => {
      let ram, memory
      if (value.includes("/")) {
         let arr = value.split("/")
         ram = arr[0]?.replace("GB", "")
         memory = arr[1]?.replace("GB", "")
      } else {
         memory = value?.replace("GB", "")
         ram = ""
      }


      if (fetchedData.component.title.includes("B", fetchedData.component.title.length - 1)) {
         let x = fetchedData.component.title.split(" ");
         x[x.length - 1] = value;
         x = x.join(" ");

         setFetchedData((prev) => ({
            ...prev,
            component: {
               ...prev.component,
               title: x,
               memory: memory,
               ram: ram
            },
         }));
      } else {
         setFetchedData((prev) => ({
            ...prev,
            component: {
               ...prev.component,
               title: prev.component.title + " " + value,
               memory: memory,
               ram: ram
            },
         }));
      }
   };


   const resetHandler = () => {
      setFetchedData(DEFAULT);
      setGalleryRefs([]);
   };


   const rebuild = async (targetSlug) => {
      await fetch(`https://snadnavolba.cz/api/rebuild?secret=41786&path=recenze/${targetSlug}`)
         .then(res => res.json()).then(success => {
            if (success.revalidated) console.log("✅");
         })
   }

   return (
      !isLoggedIn ? <Login></Login> :
         <div className="App">
            <Container maxWidth="xl">
               <header className="App-header form">

                  <div className="grid grid-cols-2 mb-20">
                     <TextField
                        key="gsm"
                        required
                        id="outlined-required"
                        className="input"
                        inputRef={urlField}
                        label="Vlož URL zdroje dat"
                        value={sourceUrl}
                        //onFocus={() => setSourceUrl("")}
                        onChange={(e) => setSourceUrl(e.target.value)}
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">
                                 {sourceUrl && (
                                    <IconButton onClick={() => setSourceUrl("")}>
                                       <Clear />
                                    </IconButton>
                                 )}
                              </InputAdornment>
                           )
                        }}
                     />

                     <div className="grid grid-cols-2">
                        <Button
                           variant="contained"
                           className="input"
                           onClick={() => (urlField.current.value ? loadData(sourceUrl) : alert("Vlož tam tu url omg!"))}
                        >
                           Načíst data ze zdroje
                        </Button>
                        <div className="flex gap">
                           <Button
                              variant="contained"
                              className="input"
                              onClick={() => combineParams()}
                           >
                              Extra parametry
                           </Button>

                           <div className="count">
                              {Object.values(fetchedData.component).filter(value => value === '' || value === null).length}
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="grid grid-cols-2 mb-20">
                     <TextField
                        key="heureka"
                        required
                        id="outlined-required"
                        className="input"
                        inputRef={urlFieldHeureka}
                        label="Vlož URL z HEUREKY"
                        value={heurekaUrl}
                        //onFocus={() => setHeurekaUrl("")}
                        onChange={(e) => setHeurekaUrl(e.target.value)}
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">
                                 {heurekaUrl && (
                                    <IconButton onClick={() => setHeurekaUrl("")}>
                                       <Clear />
                                    </IconButton>
                                 )}
                              </InputAdornment>
                           )
                        }}
                     />
                     <Button
                        variant="contained"
                        className="input"
                        onClick={() => (urlFieldHeureka.current.value ? loadDataHeureka(heurekaUrl) : handleEmptyHeureka())}
                     >
                        Načíst data z Heureky
                     </Button>
                  </div>


               </header>
               <div className="form gap space-between" style={{ display: "flex" }}>
                  <TextField
                     key="productId"
                     //className="input"
                     label="Heureka ID"
                     name="productId"
                     value={fetchedData.productId ? fetchedData.productId : ""}
                     onChange={(e) => onChangeHandler(e)}
                     onBlur={() => navigator.clipboard.writeText(fetchedData.productId)}
                  />
                  <TextField
                     key="price"
                     //className="input"
                     label="Cena"
                     name="price"
                     value={fetchedData.price ? fetchedData.price : ""}
                     onChange={(e) => onChangeHandler(e)}

                  />
                  <TextField
                     key="codeName"
                     //className="input"
                     label="Kod produktu"
                     name="codeName"
                     value={fetchedData.component?.codeName ? fetchedData.component.codeName : ""}
                     onChange={(e) => onChangeHandler(e, true)}
                     onFocus={(e) => {
                        setTimeout(() => {
                           navigator.clipboard.writeText(fetchedData.component.codeName)
                        }, 200);
                        setTimeout(() => {
                           e.target.blur()
                        }, 2000);
                     }}
                  />
                  {(category === 1 || category === 2) &&
                     <TextField
                        key="memoryVariant"
                        select
                        value={memoryVariant}
                        label="Paměť"
                        name="memoryVariant"
                        style={{ width: "100px" }}
                        onChange={(event) => memoryVariantChangeHandler(event)}
                     >
                        <MenuItem value={"32GB"}>32GB</MenuItem>
                        <MenuItem value={"64GB"}>64GB</MenuItem>
                        <MenuItem value={"2GB/32GB"}>2GB/32GB</MenuItem>
                        <MenuItem value={"3GB/32GB"}>3GB/32GB</MenuItem>
                        <MenuItem value={"4GB/64GB"}>4GB/64GB</MenuItem>
                        <MenuItem value={"128GB"}>128GB</MenuItem>
                        <MenuItem value={"4GB/128GB"}>4GB/128GB</MenuItem>
                        <MenuItem value={"6GB/128GB"}>6GB/128GB</MenuItem>
                        <MenuItem value={"8GB/128GB"}>8GB/128GB</MenuItem>
                        <MenuItem value={"256GB"}>256GB</MenuItem>
                        <MenuItem value={"6GB/256GB"}>6GB/256GB</MenuItem>
                        <MenuItem value={"8GB/256GB"}>8GB/256GB</MenuItem>
                        <MenuItem value={"12GB/256GB"}>12GB/256GB</MenuItem>
                        <MenuItem value={"512GB"}>512GB</MenuItem>
                        <MenuItem value={"8GB/512GB"}>8GB/512GB</MenuItem>
                        <MenuItem value={"12GB/512GB"}>12GB/512GB</MenuItem>
                        <MenuItem value={"1TB"}>1TB</MenuItem>
                        <MenuItem value={"8GB/1TB"}>8GB/1TB</MenuItem>
                        <MenuItem value={"12GB/1TB"}>12GB/1TB</MenuItem>
                        <MenuItem value={"16GB/1TB"}>16GB/1TB</MenuItem>

                     </TextField>
                  }
                  <TextField
                     key="categoryId"
                     select
                     value={category}
                     label="Kategorie"
                     name="categoryId"
                     style={{ width: "120px" }}
                     onChange={(e) => setCategoryHandler(e.target.value)}
                  >
                     <MenuItem value={1}>Mobily</MenuItem>
                     <MenuItem value={2}>Tablety</MenuItem>
                     <MenuItem value={3}>Notebooky</MenuItem>
                     <MenuItem value={4}>Televize</MenuItem>
                  </TextField>
                  <TextField
                     key="imageSource"
                     select
                     value={imageSource}
                     label="Obrázky"
                     name="imagesSource"
                     style={{ width: "120px" }}
                     onChange={(e) => setImageSourceHandler(e.target.value)}
                  >
                     <MenuItem value={"source"}>Zdroj</MenuItem>
                     <MenuItem value={"heureka"}>Heureka</MenuItem>
                  </TextField>

                  <ProsCons buttonTitle="Nastavit klady" items={initReviews?.pros} setter={setPros} />
                  <ProsCons buttonTitle="Nastavit zápory" items={initReviews?.cons} setter={setCons} />

                  <Button variant="contained" onClick={resetHandler}>
                     Reset
                  </Button>
               </div>
               {fetchedData?.component?.title && (
                  <div className="form">
                     <h1 className="fullsize flex justify-between">
                        <span>{fetchedData.component.title}</span>
                        <small style={{ fontSize: "0.7em" }}>
                           {category === 3 && `${fetchedData.component.cpu}, ${fetchedData.component.ram}GB/${fetchedData.component.diskSsd}GB, ${fetchedData.component.gpu}`}
                        </small>
                     </h1>

                     <div className="flex gap w100">
                        <TextField
                           key="title"
                           required
                           id="outlined-required"
                           className="f40"
                           label="Jméno produktu"
                           name="title"
                           value={fetchedData.component.title}
                           onChange={(e) => {
                              onChangeHandler(e, true);
                           }}
                        />
                        <TextField
                           key="slug"
                           required
                           id="outlined-required"
                           className="f33"
                           label="URL slug"
                           name="slug"
                           value={fetchedData.component.slug}
                           onChange={(e) => onChangeHandler(e, true)}
                        />

                        <TextField
                           key="class"
                           //className="rendered-input"
                           select
                           value={fetchedData.class}
                           label="Třída"
                           name="class"
                           className="flex-1"
                           onChange={(e) => onChangeHandler(e)}
                        >
                           <MenuItem value={"unset"}>unset</MenuItem>
                           <MenuItem value={"flagship"}>flagship</MenuItem>
                           <MenuItem value={"highend"}>highend</MenuItem>
                           <MenuItem value={"midend"}>midend</MenuItem>
                           <MenuItem value={"lowend"}>lowend</MenuItem>
                        </TextField>
                     </div>
                     <h2>Affiliate linky</h2>
                     <div className="grid grid-cols-2 gap mb-20">
                        <TextField
                           key="alzaLink"
                           required
                           id="outlined-required"
                           className="input"
                           label="Alza"
                           name="alzaLink"
                           value={fetchedData.alzaLink}
                           onChange={(e) => onChangeHandler(e)}
                        //onBlur={() => { navigator.clipboard.writeText(fetchedData.component.title) }}
                        />
                        <TextField
                           key="mallLink"
                           required
                           id="outlined-required"
                           className="input"
                           label="Mall"
                           name="mallLink"
                           value={fetchedData.mallLink}
                           onChange={(e) => onChangeHandler(e)}
                        //onBlur={() => { navigator.clipboard.writeText(fetchedData.component.title) }}
                        />
                        <TextField
                           key="okayLink"
                           required
                           id="outlined-required"
                           className="input"
                           label="Okay"
                           name="okayLink"
                           value={fetchedData.okayLink}
                           onChange={(e) => onChangeHandler(e)}
                        //onBlur={() => { navigator.clipboard.writeText(fetchedData.component.title) }}
                        />
                        <TextField
                           key="expertLink"
                           required
                           id="outlined-required"
                           className="input"
                           label="Expert"
                           name="expertLink"
                           value={fetchedData.expertLink}
                           onChange={(e) => onChangeHandler(e)}
                        //onBlur={() => { navigator.clipboard.writeText(fetchedData.component.title) }}
                        />
                        <TextField
                           key="comforLink"
                           required
                           id="outlined-required"
                           className="input"
                           label="Comfor"
                           name="comforLink"
                           value={fetchedData.comforLink}
                           onChange={(e) => onChangeHandler(e)}
                        //onBlur={() => { navigator.clipboard.writeText(fetchedData.component.title) }}
                        />
                        {category !== 4 && <TextField
                           key="smartyLink"
                           required
                           id="outlined-required"
                           className="input"
                           label="Smarty"
                           name="smartyLink"
                           value={fetchedData.smartyLink}
                           onChange={(e) => onChangeHandler(e)}
                        //onBlur={() => { navigator.clipboard.writeText(fetchedData.component.title) }}
                        />}
                        {category !== 4 &&
                           <TextField
                              key="exasoftLink"
                              required
                              id="outlined-required"
                              className="input"
                              label="Exasoft"
                              name="exasoftLink"
                              value={fetchedData.exasoftLink}
                              onChange={(e) => onChangeHandler(e)}
                           //onBlur={() => { navigator.clipboard.writeText(fetchedData.component.title) }}
                           />}
                        {(category === 1 || category === 2) &&
                           <TextField
                              key="mobilplusLink"
                              required
                              id="outlined-required"
                              className="input"
                              label="Mobilplus"
                              name="mobilplusLink"
                              value={fetchedData.mobilplusLink}
                              onChange={(e) => onChangeHandler(e)}
                           //onBlur={() => { navigator.clipboard.writeText(fetchedData.component.title) }}
                           />
                        }
                        <TextField
                           key="patroLink"
                           required
                           id="outlined-required"
                           className="input"
                           label="Patro"
                           name="patroLink"
                           value={fetchedData.patroLink}
                           onChange={(e) => onChangeHandler(e)}
                        //onBlur={() => { navigator.clipboard.writeText(fetchedData.component.title) }}
                        />
                     </div>
                     <Button variant="contained" className="fullsize" onClick={() => onSubmit()}>
                        Odeslat data na web
                     </Button>
                  </div>
               )}
               {fetchedData.component.images && /*galleryRefs.length === 0 &&*/ (
                  <div className="gallery">
                     <RUG
                        key="galerie"
                        //source={(response) => console.log(response.source)} // response image source
                        autoUpload={false}
                        onChange={(response) =>
                           setFetchedData((prev) => ({
                              ...prev,
                              component: { ...prev.component, images: response },
                           }))
                        }
                        initialState={fetchedData.component.images}
                        accept={['jpg', 'jpeg', 'webp', 'png', 'gif']}
                     />
                  </div>
               )}

               {fetchedData?.component?.title && (category === 1 || category === 2) && (
                  <PhoneFields fetchedData={fetchedData} onChangeHandler={onChangeHandler}></PhoneFields>
               )}
               {fetchedData?.component?.title && (category === 3 || category === 4) && (
                  <NotebookFields fetchedData={fetchedData} setFetchedData={setFetchedData} onChangeHandler={onChangeHandler}></NotebookFields>
               )}

               <div className="form">
                  <Button variant="contained" className="fullsize" onClick={() => onSubmit()}>
                     Odeslat data na web
                  </Button>
               </div>

            </Container>
            <Fab style={{ position: "fixed", right: "20px", bottom: "20px" }} onClick={() => window.scrollTo(0, 0)}>
               <NavigationIcon />
            </Fab>
            <Modal
               open={modalOpen}
               onClose={handleClose}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description"
            >
               <Box sx={modalStyle}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                     Zvol zdroj dat pro parametry
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                     {fetchedData.alzaLink && <Button onClick={() => { handleSourceSelect(fetchedData.alzaLink) }}>Alza</Button>}
                     {fetchedData.smartyLink && <Button onClick={() => { handleSourceSelect(fetchedData.smartyLink) }}>Smarty</Button>}
                  </Typography>
               </Box>
            </Modal>
         </div>
   );

   function slugify(str) {
      str = str.replace(/^\s+|\s+$/g, "");
      // Make the string lowercase
      str = str.toLowerCase();
      // Remove accents, swap ñ for n, etc
      var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
      var to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
      for (var i = 0, l = from.length; i < l; i++) {
         str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }
      // Remove invalid chars
      str = str
         .replace(/[^a-z0-9 -]/g, "")
         // Collapse whitespace and replace by -
         .replace(/\s+/g, "-")
         // Collapse dashes
         .replace(/-+/g, "-");
      return str;
   }
}

export default App;
