async function heureka(url) {
   if (!url.includes("heureka.cz")) {
      alert("Špatná url!");
      return;
   }

   function extractExpressionsFromUrl(url) {
      const expressions = url.split('/').filter(expression => expression !== '');
      console.log(expressions);
      const categorySlug = expressions[1].split(".heureka")[0];
      const productSlug = expressions[2];
    
      return { productSlug, categorySlug };
    }

    const expressions = extractExpressionsFromUrl(url);
    console.log(expressions);

   try {
      const response = await fetch("https://api.heureka.cz/product-detail-gateway/graphql", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            query: `
               query ProductDetail {
                  productDetail(
                     countryCode: CZECHIA
                     productSlug: "${expressions.productSlug}"
                     categorySlug: "${expressions.categorySlug}"
                  ) {
                     product {
                        media {
                              images {
                                 url
                              }
                        }
                        mainImageUrl
                        id
                     }
                     offers {
                        regular {
                              id
                              shop {
                                 slug
                              }
                              price
                        }
                        bidding {
                              price
                        }
                     }
                     reviews {
                        regular(limit: 100) {
                              rating
                              pros
                              cons
                        }
                     }
                  }
               }
          `,
         }),
      });

      const data = await response.json();
      // Uložení získaných dat do proměnné
      console.log(data.data)

      const { product, offers, reviews } = data.data?.productDetail;

      let imgs = [];
      for (const item of product.media.images) {
         const imgMeta = await getImageMeta(item.url);
         if (imgMeta[1] > 500) imgs.push({ source: item.url, name: imgMeta[0] + " x " + imgMeta[1] });
      }

      const lowestBiddingPrice = offers.bidding.reduce((lowest, current) => {
         if (current.price < lowest) {
            return current?.price;
         }
         return lowest;
      }, offers.bidding[0]?.price);

      const calculateAverageRating = (data) => {
         const ratings = data.map((item) => item.rating); // Získání pole hodnot rating
         const sum = ratings.reduce((accumulator, currentValue) => accumulator + currentValue, 0); // Sečtení hodnot rating
         const average = (sum / ratings.length) * 10; // Výpočet průměru
         return average ? average : 0;
      };

      const averageRating = calculateAverageRating(reviews.regular);


      const getAffiliateLink = async () => {
         const links = [];
         const affiliates = ["alza", "okay", "mall", "smarty", "expert", "comfor", "mobilplus", "patro", "exasoft"];
       
         for (const offer of offers.regular) {
           for (const affiliate of affiliates) {
             if (offer.shop.slug.includes(affiliate)) {
               const req = await fetch(`https://www.heureka.cz/exit/${offer.shop.slug}/${offer.id}`);
               links.push({ shop: offer.shop.slug, url: req.url?.split("utm_")[0].slice(0, -1) });
               break;
             }
           }
         }
         return links
       };
       
       
      const translateShopKey = (shop) => {
         const translations = {
           "alza-cz": "alzaLink",
           "mall-cz": "mallLink",
           "okay-cz": "okayLink",
           "expert-cz": "expertLink",
           "comfor-cz": "comforLink",
           "smarty-cz": "smartyLink",
           "exasoft-cz": "exasoftLink",
           "mobilplus-cz": "mobilplusLink",
           "patro-cz": "patroLink",
           // Další překlady klíčů obchodů
         };
       
         return translations[shop] || shop;
       };

      const affiliateLinks = await getAffiliateLink()
      
      const translatedLinks = affiliateLinks.map(({ shop, url }) => ({
         [translateShopKey(shop)] : url
       }));

      console.log(translatedLinks);


      const finalData = {
         price: lowestBiddingPrice,
         rating: averageRating.toString(),
         images: imgs,
         reviewsCount: reviews.regular.length.toString(),
         heurekaLink: url.replace("/#prehled/", "") || "",
         productId: product.id,
         reviews: reviews.regular,
         ...Object.assign({}, ...translatedLinks)
      };


      return finalData
   } catch (error) {
      alert(error)
      console.error(error);
   }
}

async function getImageMeta(url) {
   const prom = new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
   });
   const res = await prom;
   return [res.width, res.height];
}


export default heureka;
