import { extractNumber } from "./utils/utils";

async function alza(url) {
   let finalUrl = url;
   if (!url.includes("alza")) {
      alert("Špatná url!");
      return;
   }
   if (!finalUrl.includes("#parametry")) {
      finalUrl += "#parametry"
   }
   try {
      const req = await fetch(url);
      const html = await req.text();
      const DATA = await grabber(html, url);
      return DATA;
   } catch (err) {
      console.log(err);
      return false;
   }
}

function grabber(html, url) {
   //console.log(html);
   const parser = new DOMParser();
   const doc = parser.parseFromString(html, "text/html");

   if (!doc.querySelector(".container.params")) {
      alert("Nenalezena relevantní data");
      return;
   }

   const getStr = (query) => {
      let target = doc.querySelector(query);
      if (target) {
         return target.innerText.replace(/\n/g, "");
      } else {
         console.log("Nenalezen element: ", query);
         return undefined;
      }
   };

   const findUnnamed = (queryString) => {
      const searchArea = doc.querySelectorAll(".cell1");
      let str
      for (const a of searchArea) {
         if (a.innerText.replace(/\s/g, "").includes(queryString.replace(/\s/g, ""))) {
            let clonedElement = a.nextElementSibling.cloneNode(true);
            clonedElement.querySelector("span")?.remove();
            clonedElement.querySelector("span")?.remove();
            str = clonedElement.textContent.trim();
         }
      }
      return str || ""
   };

   const extractCPUModel = (string) => {
      let cpu = string.replace("Intel Core i3", "").replace("Intel Core i5", "").replace("Intel Core i7", "").replace("Intel Core i9", "").replace("AMD Ryzen 3", "").replace("AMD Ryzen 5", "").replace("AMD Ryzen 7", "").replace("AMD Ryzen 9", "").trim().split(" ")
      return cpu[0]
   }
   const extractProductCode = () => {
      return doc.querySelector("[data-testid='more-info-product-number'] [data-testid='value']").innerText || "";
   };


   let json = {
      title: getStr("h1"),
      codeName: extractProductCode(),

      width: +extractNumber(findUnnamed("Šířka")),
      height: +extractNumber(findUnnamed("Výška maximální")),
      depth: +extractNumber(findUnnamed("Hloubka")),
      weight: +extractNumber(findUnnamed("Hmotnost")) * 1000 || "",

      displayType: findUnnamed("Typ panelu"),
      displayResolution: findUnnamed("Maximální rozlišení"),
      displaySize: extractNumber(findUnnamed("Úhlopříčka displeje")),
      displayTouch: findUnnamed("Výbava").includes("Dotykový displej") ? "Ano" : "Ne",

      displaySurface: findUnnamed("Typ displeje"),
      displayFrequency: extractNumber(findUnnamed("Obnovovací frekvence displeje")),
      displayRatio: findUnnamed("Poměr stran"),
      displayNits: extractNumber(findUnnamed("Svítivost")),

      webcam: findUnnamed("Webkamera"),
      webcamCover: "",

      cpu: findUnnamed("Typ procesoru"),
      cpuModel: extractCPUModel(findUnnamed("Modelové označení procesoru")),
      cpuFrequency: findUnnamed("Frekvence procesoru"),
      cpuCores: extractNumber(findUnnamed("Počet jader procesoru")),
      cpuBoost: findUnnamed("Core Boost Frekvence"),
      cpuTdp: findUnnamed("TDP"),
      cpuCache: findUnnamed("Cache procesoru"),
      cpuGen: findUnnamed("Generace procesoru"),

      gpu: findUnnamed("Čip grafické karty"),
      gpuType: findUnnamed("Typ grafické karty"),
      gpuCores: findUnnamed("Počet jader GPU"),
      gpuMemory: findUnnamed("Paměť grafické karty"),
      gpuFrequency: "",
      gpuTgp: findUnnamed("Maximální grafický příkon (TGP)"),

      ram: extractNumber(findUnnamed("Velikost operační paměti RAM")),
      ramType: findUnnamed("Typ paměti"),
      ramFrequency: findUnnamed("Frekvence paměti"),

      diskHdd: extractNumber(findUnnamed("HDD Kapacita")?.split(" ")[0]),
      diskSsd: extractNumber(findUnnamed("SSD Kapacita")?.split(" ")[0]),
      diskSsdType: findUnnamed("Rozhraní disku"),

      batteryCapacity: extractNumber(findUnnamed("Kapacita baterie")),
      batteryCells: extractNumber(findUnnamed("Počet článků")),
      batteryDuration: findUnnamed("Maximální výdrž baterie"),
      usbcPd: findUnnamed("Nabíjení přes USB-C"),
      chargingCurrent: findUnnamed("Nabíjecí příkon"),
      
      hdmi: `${findUnnamed("Grafické výstupy")?.includes("HDMI") ? "Ano" : "Ne"}`,
      usb2: extractNumber(findUnnamed("USB 2")),
      usb3: extractNumber(findUnnamed("USB 3")),
      usbC: extractNumber(findUnnamed("USB-C")),
      thunderbolt: extractNumber(findUnnamed("Thunderbolt")),
      bluetooth: extractNumber(findUnnamed("Bluetooth")),
      wifi: findUnnamed("Verze WiFi"),
      wifiBand: findUnnamed("WiFi"),
      rj45: findUnnamed("Síťové")?.includes("RJ-45") ? "Ano" : "Ne",
      opticalDrive: findUnnamed("Optická mechanika") || "",

      network: "",
      memorySlot: findUnnamed("Výbava").includes("Čtečka paměťových karet") ? "Ano" : "Ne",
      chasiMaterial: findUnnamed("Materiál konstrukce"),
      stereoSpeakers: "",
      keyboardBacklit: findUnnamed("Výbava").includes("Podsvícená klávesnice") ? "Ano" : "Ne",
      keyboardNumeric: findUnnamed("Výbava").includes("Numerická klávesnice") ? "Ano" : "Ne",
      microphone: "Ano",
      jack: findUnnamed("Audio")?.includes("Jack") ? "Ano" : "",
      tpm2: "",
      sensors: "",
      os: findUnnamed("Operační systém"),
      alzaLink: url.replace("#parametry", ""),
   };

   console.log(json);
   return json;
}

export default alza;
