import { extractNumber } from "./utils/utils";

async function alza(url) {
   let finalUrl = url;

   if (!url.includes("alza")) {
      alert("Špatná url!");
      return;
   }
   if (!finalUrl.includes("#parametry")) {
      finalUrl += "#parametry";
   }

   try {
      const req = await fetch(url);
      const html = await req.text();
      const DATA = await grabber(html, url);
      return DATA;
   } catch (err) {
      console.log(err);
      return false;
   }
}

function grabber(html, url) {
   //console.log(html);
   const parser = new DOMParser();
   const doc = parser.parseFromString(html, "text/html");

   if (!doc.querySelector(".container.params")) {
      alert("Nenalezena relevantní data");
      return;
   }

   const getStr = (query) => {
      let target = doc.querySelector(query);
      if (target) {
         return target.innerText.replace(/\n/g, "");
      } else {
         console.log("Nenalezen element: ", query);
         return undefined;
      }
   };

   const findUnnamed = (queryString) => {
      if (!queryString) return ""
      const searchArea = doc.querySelectorAll(".cell1");
      let str;
      for (const a of searchArea) {
         if (a.innerText.replace(/\s/g, "").includes(queryString.replace(/\s/g, ""))) {
            let clonedElement = a.nextElementSibling.cloneNode(true);
            clonedElement.querySelector("span")?.remove();
            clonedElement.querySelector("span")?.remove();
            str = clonedElement.textContent.trim();
         }
      }
      return str || "";
   };

   const trueFalse = (string, term) => {
      if (!string || !term) return ""
      return string.includes(term) ? "Ano" : ""
   }

   const extractProductCode = () => {
      return doc.querySelector("[data-testid='more-info-product-number'] [data-testid='value']").innerText || "";
   };

   function extractHDMIVersion(str) {
      const items = str.split(',');

      let version = null;
      items.forEach((item) => {
         const trimmedItem = item.trim();
         if (trimmedItem.includes('HDMI')) {
            const words = trimmedItem.split(' ');
            version = words[1];
         }
      });

      return version;
   }


   function extractCIString(str) {
      const items = str.split(',');

      let ciString = '';
      items.forEach((item) => {
         const trimmedItem = item.trim();
         if (trimmedItem.includes('CI')) {
            ciString += trimmedItem + ', ';
         }
      });
      ciString = ciString.replace(/,\s*$/, '');

      return ciString || null;
   }


   let json = {
      title: getStr("h1"),
      codeName: extractProductCode(),
      diagonal: findUnnamed("Úhlopříčka"),
      panelType: findUnnamed("Technologie"),
      resolution: findUnnamed("Maximální rozlišení")?.includes("4K") ? `4K Ultra HD, 3840 × 2160 px` : findUnnamed("Maximální rozlišení"),
      frequency: findUnnamed("Obnovovací frekvence panelu"),
      hdr: findUnnamed("Podporované HDR standardy"),
      panelBacklit: findUnnamed("Podsvícení"),
      nits: findUnnamed("Maximální jas"),
      tuner: findUnnamed("Typ tuneru"),
      usb: findUnnamed("USB"),
      hdmi: findUnnamed("HDMI"),
      wifi: trueFalse(findUnnamed("Bezdrátové připojení"), "WiFi"),
      lan: trueFalse(findUnnamed("Vstupy a výstupy"), "LAN"),
      ci: extractCIString(findUnnamed("Vstupy a výstupy")),
      bluetooth: trueFalse(findUnnamed("Bezdrátové připojení"), "Bluetooth"),
      dlna: trueFalse(findUnnamed("Bezdrátové připojení"), "DLNA"),
      miracast: trueFalse(findUnnamed("Bezdrátové připojení"), "Miracast"),
      chromecast: trueFalse(findUnnamed("Bezdrátové připojení"), "Chromecast"),
      toslink: trueFalse(findUnnamed("Vstupy a výstupy"), "Digitální optický"),
      spdif: trueFalse(findUnnamed("Vstupy a výstupy"), "Digitální audio výstup"),
      composite: trueFalse(findUnnamed("Vstupy a výstupy"), "Kompozitní"),
      jack: trueFalse(findUnnamed("Vstupy a výstupy"), "Sluchátkový výstup"),
      airplay: trueFalse(findUnnamed("Bezdrátové připojení"), "AirPlay"),
      mobilePairing: trueFalse(findUnnamed("Bezdrátové připojení"), "Párování s mobilním zařízením"),
      os: findUnnamed("Operační systém"),
      smartFeatures: findUnnamed("SMART funkce"),
      smartRemote: trueFalse(findUnnamed("Ovladač v balení"), "Chytrý"),
      gameMode: findUnnamed("Herní funkce"),
      usbRecording: trueFalse(findUnnamed("Multimediální funkce"), "Nahrávání na USB"),
      usbPlayback: trueFalse(findUnnamed("Multimediální funkce"), "Přehrávání z USB"),
      apps: findUnnamed("Aplikace"),
      voiceControl: trueFalse(findUnnamed("SMART funkce"), "Ovládání hlasem"),
      voiceAssistant: findUnnamed("Kompatibilita s hlasovým asistentem"),
      soundPerformance: findUnnamed("Výkon reproduktorů"),
      //soundSetup: findUnnamed(""),
      energyClassSdr: findUnnamed("Energetická třída v SDR režimu"),
      energyConsumptionSdr: findUnnamed("Spotřeba v SDR režimu"),
      energyClassHdr: findUnnamed("Energetická třída v HDR režimu"),
      energyConsumptionHdr: findUnnamed("Spotřeba v HDR režimu"),
      vesa: findUnnamed("VESA uchycení"),
      color: findUnnamed("Barva"),
      hdmiVersion: extractHDMIVersion(findUnnamed("Vstupy a výstupy")),
      ambilight: trueFalse(findUnnamed("Multimediální funkce"), "Ambilight"),
      lightSensor: trueFalse(findUnnamed("Multimediální funkce"), "Světelný senzor"),
      width: +extractNumber(findUnnamed("Šířka")),
      height: +extractNumber(findUnnamed("Výška")),
      depth: +extractNumber(findUnnamed("Hloubka")),
      weight: +extractNumber(findUnnamed("Hmotnost")) || "",
      standWidth: findUnnamed("Šířka podstavce / rozteč nožiček"),
      heightWoStand: findUnnamed("Výška bez podstavce"),
      depthWoStand: findUnnamed("Hloubka bez podstavce"),
      modelYear: findUnnamed("Modelový rok"),
      alzaLink: url.replace("#parametry", ""),
   };

   console.log(json);
   return json;
}

export default alza;
