import { extractNumber } from "./utils/utils";

async function smarty(url) {
   if (!url.includes("smarty")) {
      alert("Špatná url!");
      return;
   }
   try {
      const req = await fetch(url);
      console.log(req);
      const html = await req.text();
      const DATA = await grabber(html, url);
      return DATA;
   } catch (err) {
      console.log(err);
      return false;
   }
}

function grabber(html, url) {
   //console.log(html);
   const parser = new DOMParser();
   const doc = parser.parseFromString(html, "text/html");

   if (!doc.querySelector(".pnlAttribs")) {
      alert("Nenalezena relevantní data");
      return;
   }

   const dimensions = (index) => {
      let arr = findUnnamed("Rozměry")?.split(",");
      if (!arr) return ""
      return extractNumber(arr[index]);
   };

   const getStr = (query) => {
      let target = doc.querySelector(query);
      if (target) {
         return target.innerText.replace(/\n/g, "");
      } else {
         console.log("Nenalezen element: ", query);
         return undefined;
      }
   };

   const extractHDMI = (string) => {
      let y
      string.split(",")?.forEach(item => {
         if (item.includes("HDMI")) y = item.replace("HDMI", "")
      })
      return y
   }

   const findUnnamed = (queryString) => {
      const searchArea = doc.querySelectorAll(".pnlAttribs .row .l");
      let str
      for (const a of searchArea) {
         if (a.innerHTML.includes(queryString
            .replace(" ", "&nbsp;")
            .replace(" ", "&nbsp;")
            .replace(" ", "&nbsp;")
            .replace(" ", "&nbsp;")
            .replace(" ", "&nbsp;")
            .replace(" ", "&nbsp;")
         )) {
            str = a.nextElementSibling.textContent.trim();
         }
      }
      return str || ""
   };

   const cleanup = (string) => {
      let arr = string.split(" ");
      arr.forEach((item, index) => {
         if (item === "&amp;") arr[index] = "&";
      });
      return arr.join(" ");
   };

   const extractProductCode = () => {
      let nodes = doc.querySelectorAll(".short div");
      let x;
      console.log(nodes);
      nodes.forEach((item) => {
         console.log(item.innerText);
         if (item.innerText.includes("P/N")) {
            console.log(item.innerHTML.split("&nbsp;")[1]);
            x = item.innerHTML.split("&nbsp;")[1];
         }
      });
      return x;
   };
   const getStorage = (name) => {
      const disk = {
         diskTotal: extractNumber(findUnnamed("Velikost úložiště")),
         diskHdd: extractNumber(findUnnamed("Kapacita HDD")),
         diskSsd: extractNumber(findUnnamed("Kapacita SSD")),
         diskSsdType: findUnnamed("Typ SSD"),
      };
      if (name === "diskSsd") {
         return disk[name] || disk.diskTotal;
      } else {
         return disk[name];
      }
   };

   const getImages = () => {
      const imgs = [];

      const galleryRef = doc.querySelectorAll(".carousel .owl-carousel picture img");
      galleryRef.forEach((item, index) => {
         if (!item.getAttribute("src")?.includes("smarty.cz")) {
            console.log("stav1");

            let str1 = item.getAttribute("src").split("-")[0]
            imgs.push({ source: "https://doc.smarty.cz" + str1 + "-1000-1000.webp", name: index });
         } else {
            let str2 = item.getAttribute("src").split("-")[0]
            imgs.push({ source: str2 + "-1000-1000.webp", name: index });
         }
      })
      return imgs
   };

   let json = {
      title: getStr("h1"),
      codeName: extractProductCode(),

      width: dimensions(1),
      height: dimensions(0),
      depth: dimensions(2),
      weight: extractNumber(findUnnamed("Hmotnost")) * 1000 || "",

      images: getImages(),

      displayType: findUnnamed("Typ displeje"),
      displayResolution: findUnnamed("Rozlišení displeje"),
      displaySize: extractNumber(findUnnamed("Velikost displeje")),
      displayTouch: findUnnamed("Dotykový displej"),
      displaySurface: findUnnamed("Povrch displeje"),
      displayFrequency: extractNumber(findUnnamed("Maximální obnovovací frekvence displeje")),
      displayRatio: findUnnamed("Poměr stran"),
      displayNits: extractNumber(findUnnamed("Maximální jas displeje")),

      webcam: findUnnamed("Rozlišení webkamery"),
      webcamCover: findUnnamed("Krytka webkamery"),

      cpu: findUnnamed("Procesor"),
      cpuModel: findUnnamed("Modelové označení procesoru"),
      cpuFrequency: findUnnamed("Max. frekvence procesoru"),
      cpuCores: findUnnamed("Počet jader procesoru"),
      cpuBoost: findUnnamed("Boost frekvence procesoru"),
      cpuTdp: "",
      cpuCache: findUnnamed("Cache procesoru"),
      cpuGen: findUnnamed("Generace procesoru"),

      gpu: findUnnamed("Grafická karta"),
      gpuType: findUnnamed("Typ grafické karty"),
      gpuCores: findUnnamed("Počet jader GPU"),
      gpuMemory: findUnnamed("Paměť grafické karty"),
      gpuFrequency: "",
      gpuTgp: findUnnamed("Maximální grafický příkon"),

      ram: extractNumber(findUnnamed("Velikost RAM")),
      ramType: findUnnamed("Typ paměti RAM"),
      ramFrequency: findUnnamed("Frekvence paměti RAM"),

      diskHdd: getStorage("diskHdd"),
      diskSsd: getStorage("diskSsd"),
      diskSsdType: getStorage("diskSsdType"),

      batteryCapacity: extractNumber(findUnnamed("Kapacita baterie")),
      batteryCells: findUnnamed("Baterie"),
      batteryDuration: findUnnamed("Výdrž baterie (WiFi a web)"),
      chargingCurrent: "",
      hdmi: `${findUnnamed("Počet HDMI")}${findUnnamed("Drátová připojení")?.includes("HDMI") ? extractHDMI(findUnnamed("Drátová připojení")) : ""}`,
      usb2: extractNumber(findUnnamed("Počet USB 2.0 Type-A")),
      usb3: extractNumber(findUnnamed("Počet USB 3.0/3.1/3.2 Gen 1 Type-A:")),
      usbC: extractNumber(findUnnamed("Type-C")),
      thunderbolt: extractNumber(findUnnamed("Thunderbolt")),
      bluetooth: findUnnamed("Verze Bluetooth"),
      wifi: findUnnamed("WiFi standard"),
      wifiBand: findUnnamed("Pásma WiFi"),
      rj45: findUnnamed("Drátová připojení")?.includes("RJ-45") ? "Ano" : "",
      opticalDrive: findUnnamed("Optická mechanika") || "",
      network: findUnnamed("Typ síťové karty"),
      memorySlot: findUnnamed("Slot na paměťové karty"),
      usbcPd: findUnnamed("Nabíjení přes USB-C PD"),
      chasiMaterial: findUnnamed("Materiál konstrukce") || findUnnamed("Materiál"),
      stereoSpeakers: findUnnamed("Stereo reproduktory"),
      keyboardBacklit: findUnnamed("Podsvícená klávesnice"),
      keyboardNumeric: findUnnamed("Numerická klávesnice"),
      microphone: findUnnamed("Mikrofon"),
      jack: findUnnamed("Drátová připojení")?.includes("3,5") ? "Ano" : "",
      tpm2: findUnnamed("TPM 2"),
      sensors: findUnnamed("Senzory"),
      os: findUnnamed("Operační systém"),
      smartyLink: url,
   };

   console.log(json);

   return json;
}

export default smarty;
