export const phone = (data) => {
   return {
      __component: "specs.phone",
      network: data.network,
      speed: data.speed,
      announced: data.announced,
      //status: data.status,
      body: data.body,
      bodyOther: data.bodyOther,
      stylus: data.stylus,
      waterResistant: data.waterResistant,
      sim: data.sim,
      displayType: data.displayType,
      displaySize: data.displaySize,
      displayResolution: data.displayResolution,
      displayFrequency: data.displayFrequency,
      displayNits: data.displayNits,
      displayPpi: data.displayPpi,
      displayRatio: data.displayRatio,
      displayToBodyRatio: data.displayToBodyRatio,
      displayProtection: data.displayProtection,
      displayOther: data.displayOther,
      displayDolbyVision: data.displayDolbyVision,
      displayHdr: data.displayHdr,
      bezelWidth: data.displayBezel,
      os: data.os,
      chipset: data.chipset,
      ram: data.ram,
      cpu: data.cpu,
      gpu: data.gpu,
      memory: data.memory,
      memoryType: data.memoryType,
      memorySlot: data.memorySlot,
      mainCameras: data.mainCameras && data.mainCameras.join(" ]$[ "),
      mainCamerasFeatures: data.mainCamerasFeatures,
      mainCamerasVideo: data.mainCamerasVideo,
      frontCameras: data.frontCameras && data.frontCameras.join(" ]$[ "),
      frontCamerasFeatures: data.frontCamerasFeatures,
      frontCamerasVideo: data.frontCamerasVideo,
      speakers: data.speakers,
      jack: data.jack,
      wlan: data.wlan,
      bluetooth: data.bluetooth,
      gps: data.gps,
      nfc: data.nfc,
      radio: data.radio,
      usb: data.usb,
      sensors: data.sensors,
      features: data.features && data.features.join(" ]$[ "),
      battery: data.battery,
      charging: data.charging && data.charging.join(" ]$[ "),
      wirelessCharging: data.wirelessCharging && data.wirelessCharging.join(" ]$[ "),
      colors: data.colors,
      sarEu: data.sarEu,
      benchmarks: data.benchmarks && data.benchmarks.join(" ]$[ "),
   };
};
