import TextField from "@mui/material/TextField";

export const NotebookFields = ({ fetchedData, onChangeHandler }) => {
   console.log(fetchedData.component, "fdc");


   return (
      <div className="flex flex-wrap form row">
         {fetchedData.component && Object.keys(fetchedData.component).map((item, index) => {

            return (
               <TextField
                  key={item}
                  //required
                  id="outlined-required"
                  className="rendered-input"
                  label={item === "height" ? "tloušťka" : item}
                  name={item}
                  value={fetchedData.component[item]}
                  onChange={(e) => onChangeHandler(e, true)}
               />
            );
         })}
      </div>
   );
};
